// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
  padding-bottom: 70px;
  color: #262626;
  line-height: 1.7;

  :global(.ant-collapse-header) {
    padding: 12px 0 !important;
  }

  legend {
    display: block;
    width: 100%;
    padding: 20px 0 10px 0;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1;
    color: rgba(0, 0, 0, 0.45);
    border: 0;
    border-bottom: 1px solid #d9d9d9;
  }

  .label {
    text-align: right;
    font-weight: 500;
  }

  .unitTitle {
    font-weight: bold;
  }

  .unitTitleFooter {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  .calendarOutlined {
    margin-right: 5px;
  }

  .footer {
    height: 60px;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    left: 0;
    background: #fff;
    border-radius: 0 0 4px 4px;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .buttonsContainer {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .saveButton {
        margin-left: 20px;
      }
    }

    .cancelBookingButton {
      color: $tbf-white;
      background-color: $tbf-red;
      border: $tbf-red;
      width: 100%;
      padding: 10px;
      height: 40px;
      &:focus,
      &:hover {
        color: $tbf-white;
        background-color: $tbf-red;
        border: $tbf-red;
      }
    }

    .disabledBookingButton {
      @extend .cancelBookingButton;
      color: $tbf-black;
      background-color: $tbf-lightGrey;
      &:focus,
      &:hover {
        color: $tbf-black;
        background-color: $tbf-lightGrey;
        border: $tbf-lightGrey;
      }
    }
  }
}
