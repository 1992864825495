// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
  background: transparent;
}

.inventory_container {
  display: flex;
  border: none;
  height: calc(100vh - 50px);
  width: 95%;
  margin: 30px auto;
  
  @media (min-width: 1441px) {
    width: 80%;
  }
}
