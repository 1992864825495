// Generated with util/create-component.js
@import 'variables.scss';
@import 'typography.scss';

.root {
  @include font-defaults;

  :global(.ant-form-item) {
    margin-bottom: 3px;
  }

  :global(.ant-select-item-group),
  :global(.ant-form-item-label > label) {
    text-transform: capitalize;
  }
};

.row {
  border-bottom: 1px solid $tbf-lightGrey;
  color: rgba(0, 0, 0, 0.85);
  padding: 10px 0;
}
