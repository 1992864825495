// Generated with util/create-component.js
@import 'variables.scss';
@import 'typography.scss';
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap');

.giftCardUnitSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 40px;
  margin-bottom: 40px;
}

.loadingContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 150px;
}

.roomTypeOptionHeader {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 20px 0;
}

.roomTypeTable {
  font-size: 16px;
  margin-bottom: 40px;
}

.roomTypeHeaderColumn {
  background-color: #f1f6f7;
  border-bottom: 1px solid var(--border-color);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  font-feature-settings: 'liga' off;
}

.roomTypeInfo {
  color: rgb(51, 51, 51);
}

.roomTypeTitle {
  font-size: 20px;
}

.roomTypeDescription,
.roomTypeNights {
  font-size: 16px;
}

.roomTypeOccupancy {
  font-size: 16px;
  display: flex;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.roomTypeContainer {
  padding: 20px 0;
  border-bottom: 1px solid #d8dce1;
}

.lastColumnLabel {
  display: flex;
  justify-content: flex-end;
}
 
.total {
  padding-top: 16px;
  margin-top: 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.2px;

  .totalPrice {
    font-size: 24px;
  }
}

.upsellSection {
  background-color: #f1f6f7;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.upsellLegend {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

@media screen and (max-width: 767px) {
  .lastColumnLabel {
    display: none;
  }

  .total {
    font-size: 16px;

    .totalPrice {
      font-size: 18px;
    }
  }
}
